@media only screen and (min-width: 990px) {
    .ExploreContainer-map {
	position: absolute;
	left: 350px;
	top: 0px;
	width: calc(100vw - 350px);
	height: 100vh;
	z-index: 0;
    }
}

@media only screen and (max-width: 990px) {
    .ExploreContainer-map {
	position: absolute;
	left: 0px;
	top: 52px; /* push down for the header */
	width: 100vw;
	height: calc(100vh - 52px);
	z-index: 0;
    }
}
